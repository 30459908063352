/* You can add global styles to this file, and also import other style files */
@import '../../../packages/lefty-assets/src/lib/css/billboard.scss';
@import '../../../node_modules/flag-icons/css/flag-icons.min.css';

body {
  margin: 0;

  // we must keep body transparent
  // or when converting PDF to PPTX using libreoffice,
  // it will render completely broken
  background-color: transparent;
}
